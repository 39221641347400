import ICAL from 'ical.js'

export function makeIcalEvent(attendance = {}) {
  return {
    summary: `Classfy - ${attendance.subjectName || '-'} (${
      attendance.subjectLevel || '-'
    })`,
    start: attendance.classStart,
    end: attendance.classEnd,
    description: attendance.teacherName
      ? 'Asistir a clase con ' + attendance.teacherName
      : ''
  }
}

export function generateICSFile(events = []) {
  const calendar = new ICAL.Component(['vcalendar', [], []])

  calendar.addPropertyWithValue('version', '2.0')
  calendar.addPropertyWithValue('prodid', '-//Classfy//Calendario//ES')

  events.forEach(event => {
    const vevent = new ICAL.Component('vevent')
    const eventObj = new ICAL.Event(vevent)

    eventObj.summary = event.summary || 'Sin título'
    eventObj.startDate = ICAL.Time.fromJSDate(new Date(event.start), true)
    eventObj.endDate = ICAL.Time.fromJSDate(new Date(event.end), true)
    eventObj.location = event.location || 'Madrid'
    eventObj.description = event.description || ''

    calendar.addSubcomponent(vevent)
  })

  const blob = new Blob([calendar.toString()], { type: 'text/calendar' })

  const url = URL.createObjectURL(blob)

  const link = document.createElement('a')
  link.href = url
  link.download = 'classfy-events.ics'
  document.body.appendChild(link)
  link.click()

  document.body.removeChild(link)
  URL.revokeObjectURL(url)
}

export function generateGoogleCalendarLink({
  summary = '',
  start,
  end,
  description = '',
  location = 'Madrid'
}) {
  const baseUrl = 'https://calendar.google.com/calendar/render?action=TEMPLATE'
  const params = new URLSearchParams({
    text: summary,
    dates: `${formatDateToGoogle(start)}/${formatDateToGoogle(end)}`,
    details: description,
    location: location
  })

  return `${baseUrl}&${params.toString()}`
}

export function generateCalendarLink(
  { summary = '', start, end, description = '', location = 'Madrid' },
  service = '' //   'google', 'outlook', 'yahoo', 'ics'
) {
  switch (service.toLowerCase()) {
    case 'google': {
      const formattedStart = formatDateToGoogle(start)
      const formattedEnd = formatDateToGoogle(end)
      const googleParams = new URLSearchParams({
        text: summary,
        dates: `${formattedStart}/${formattedEnd}`,
        details: description,
        location: location
      })
      return `https://calendar.google.com/calendar/render?action=TEMPLATE&${googleParams.toString()}`
    }
    case 'outlook': {
      const formattedStart = formatDateToOutlook(start)
      const formattedEnd = formatDateToOutlook(end)
      const outlookParams = new URLSearchParams({
        path: '/calendar/action/compose',
        rru: 'addevent',
        startdt: formattedStart,
        enddt: formattedEnd,
        subject: summary,
        body: description,
        location: location
      })
      return `https://outlook.office.com/calendar/0/deeplink/compose?${outlookParams.toString()}`
    }
    case 'yahoo': {
      const yahooParams = new URLSearchParams({
        v: 60,
        title: summary,
        st: formatDateToYahoo(start),
        et: formatDateToYahoo(end),
        desc: description,
        in_loc: location
      })
      return `https://calendar.yahoo.com/?${yahooParams.toString()}`
    }
    case 'ics': {
      return generateICSFile([{ summary, start, end, description, location }])
    }

    default: {
      console.warn('Unsupported calendar service')
      return null
    }
  }
}

export function formatDateToGoogle(date) {
  return new Date(date).toISOString().replace(/[-:]/g, '').split('.')[0] + 'Z' // YYYYMMDDTHHMMSSZ
}

export function formatDateToOutlook(date) {
  const d = new Date(date)
  return (
    d.getFullYear() +
    '-' +
    String(d.getMonth() + 1).padStart(2, '0') +
    '-' +
    String(d.getDate()).padStart(2, '0') +
    'T' +
    String(d.getHours()).padStart(2, '0') +
    ':' +
    String(d.getMinutes()).padStart(2, '0') +
    ':' +
    String(d.getSeconds()).padStart(2, '0')
  ) // YYYY-MM-DDTHH:MM:SS
}
function formatDateToYahoo(date) {
  const d = new Date(date)
  return (
    d.getFullYear() +
    String(d.getMonth() + 1).padStart(2, '0') +
    String(d.getDate()).padStart(2, '0') +
    'T' +
    String(d.getHours()).padStart(2, '0') +
    String(d.getMinutes()).padStart(2, '0') +
    String(d.getSeconds()).padStart(2, '0')
  ) //YYYYMMDDTHHMMSS
}

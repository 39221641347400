//@ts-check
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { isEmpty, pick } from 'ramda'
import * as Sentry from '@sentry/react'
import io from 'socket.io-client'
import Peer from 'simple-peer'
import adapter from 'webrtc-adapter'
import { useLocation } from 'react-router-dom'
import getLocalStream, { getConstraints } from 'utils/getLocalStream'
import detector from 'utils/detector'
import { AttendanceTypes } from 'utils/constants'
import config from 'config'
import { fetchICEServers } from 'api/iceConnection'
import { useAuth } from 'context/AuthProvider'
import {
  emitIdentity,
  joinTeacherClass,
  reconnectSocket,
  requestTeacherList,
  sendLogMessage,
  sendPeerSignal
} from 'api/sockets'
import usePrevious from './usePrevious'
import useLocalStorage from './useLocalStorage'
import useCustomNavigate from './useCustomNavigate'

let socket = null
let peerConnection = null
let localAuthenticated = false
let localActiveTeacher = ''
const noop = (str = '') => {}
const initialState = {
  iceServers: [],
  teachers: [],
  activeTeacher: null,
  localStream: null,
  remoteStream: null,
  authenticated: false,
  isReconnecting: false,
  isWaiting: false,
  selectedMedia: { audioInputId: '', audioOutputId: '', videoInputId: '' },
  blockNavigation: true
}
/**
 * @typedef WebRTCState
 * @property {Array} iceServers
 * @property {Array} teachers
 * @property {object} teacherData
 * @property {string} activeTeacher
 * @property {any} localStream
 * @property {any} remoteStream
 * @property {import('./useMediaSetup').SelectedMedia} selectedMedia
 * @property {boolean} authenticated
 * @property {boolean} isReconnecting
 * @property {boolean} canConnectSocketEvents
 * @property {boolean} isTest
 * @property {boolean} isWaiting
 * @property {boolean} blockNavigation
 * @property {any} socket
 * @property {object} identity
 * @property {import('react-router-dom').Location} location
 * @property {() => void} toogleIsWaiting
 * @property {() => Promise<any>} requestIceServers
 * @property {(constraints: object) => void} saveSelectedMedia
 * @property {() => Promise<any>} initUserMedia
 * @property {(selection: Partial<import('./useMediaSetup').SelectedMedia>) => Promise<any>} changeStream
 * @property {() => void} initSocketEvents
 * @property {() => void} prepareConnection
 * @property {() => void} endSocketEvents
 * @property {() => void} endPeerConnection
 * @property {(teacherId: string) => void} handleActiveTeacher
 */

/**
 *
 * @param {(str: string)=> void} onError
 * @returns {WebRTCState}
 */
export default function useWebRTC(onError = noop) {
  const location = useLocation()

  const [state, setState] = useState({
    ...initialState,
    activeTeacher: location.state?.teacherId,
    attendance: {
      id: location.state?.id,
      teacherId: location.state?.teacherId,
      type: location.state?.type,
      subjectId: location.state?.subjectId,
      subjectName: location.state?.subjectName,
      subjectLevel: location.state?.subjectLevel,
      subjectSublevel: location.state?.subjectSublevel
    }
  })

  const { getParsedItem: getVideoTourViewedValue } = useLocalStorage(
    config.isVideoTourViewedKey,
    false
  )
  const { getParsedItem: getHasMediaPermissionsValue } = useLocalStorage(
    config.hasMediaPermissionsKey,
    false
  )
  const { getParsedItem: getSelectedMediaValue, saveItem: storeSelectedMedia } =
    useLocalStorage(config.selectedMediaKey, initialState.selectedMedia)
  const navigate = useCustomNavigate()
  const localStreamRef = useRef(null)

  const {
    iceServers,
    teachers,
    activeTeacher,
    remoteStream,
    authenticated,
    isReconnecting,
    attendance,
    isWaiting,
    localStream,
    blockNavigation
  } = state

  const isTest = attendance.type === AttendanceTypes.PRUEBA
  const canConnectSocketEvents = useMemo(
    () => !isEmpty(iceServers),
    [iceServers]
  )
  const { student } = useAuth()
  const previousLocation = usePrevious(location)
  const identity = useMemo(
    () =>
      pick(
        [
          '_id',
          'id',
          'name',
          'attendance',
          'role',
          'device',
          'barbeibotAccess',
          'accessType'
        ],
        {
          ...student,
          role: 'STUDENT',
          attendance,
          device: detector()
        }
      ),
    [attendance, student]
  )

  const requestIceServers = useCallback(
    () =>
      fetchICEServers().catch(err => {
        console.error('Error connecting with ICEServers:', err)
        throw new Error('Error estableciendo videollamada')
      }),
    []
  )
  const saveSelectedMedia = selectedMedia => storeSelectedMedia(selectedMedia)

  const initUserMedia = useCallback(async () => {
    /** @type {WebRTCState['selectedMedia']} */
    const { audioInputId, videoInputId } = getSelectedMediaValue()
    return getLocalStream(getConstraints(audioInputId, videoInputId)).catch(
      err => {
        console.error(
          'Error connecting with LocalStream (selectedMedia): %o, trying default...',
          err
        )
        return getLocalStream(getConstraints()).catch(err => {
          console.error(
            'Error connecting with LocalStream (default): %o, trying selected audio...',
            err
          )
          return getLocalStream({
            audio: audioInputId
              ? { deviceId: { exact: audioInputId } }
              : adapter.browserDetails.browser === 'chrome'
              ? { deviceId: { exact: 'default' } }
              : true
          }).catch(err => {
            err => {
              console.error(
                'Error connecting with LocalStream (selected audio): %o, trying default audio...',
                err
              )
              //Try only audio
              return getLocalStream({
                audio:
                  adapter.browserDetails.browser === 'chrome'
                    ? { deviceId: { exact: 'default' } }
                    : true
              }).catch(err => {
                console.error(
                  'Error connecting with LocalStream (default audio):',
                  err
                )
                throw new Error('Error activando el dispositivo multimedia')
              })
            }
          })
        })
      }
    )
  }, [getSelectedMediaValue])

  const changeStream = async (selection = initialState.selectedMedia) => {
    try {
      if (!localStreamRef.current) return
      localStreamRef.current?.getTracks().forEach(track => track.stop())

      storeSelectedMedia(selection)
      const newStream = await initUserMedia()
      localStreamRef.current = newStream
      setState(state => ({ ...state, localStream: newStream }))
      newStream.getTracks().forEach(track => {
        peerConnection.addTrack(track, newStream)
      })
    } catch (err) {
      console.error('Error changing stream:', err)
    }
  }

  const prepareConnection = useCallback(async () => {
    try {
      Sentry.setUser(identity)
      const iceServers = await requestIceServers()
      setState(state => ({ ...state, iceServers }))
    } catch (error) {
      Sentry.captureException(error)
      onError(error.message)
    }
  }, [identity, requestIceServers, onError])

  const connect = useCallback(async () => {
    if (socket.connected && !localAuthenticated) {
      config.logWebRTC && console.log('Conectado')
      config.logWebRTC && console.log('SOCKET ID: ', socket.id)

      try {
        await emitIdentity({
          socket,
          data: identity
        })
        config.logWebRTC && console.log('Identificado')
        const teachers = await requestTeacherList({ socket })
        config.logWebRTC && console.log('Lista de profesores recibida')
        setState(state => ({
          ...state,
          teachers,
          authenticated: true
        }))
        localAuthenticated = true
      } catch (e) {
        Sentry.captureException(e)
        console.error('Error in emitIdentity:', e)
        if (e.codeMessage === 'DUPLICATE_CONNECTION')
          return onError('Ya estás conectado desde otra pestaña o dispositivo.')
        if (e.codeMessage === 'INVALID_DATA')
          return onError('No se ha podido validar tu identidad.')
        else onError(e.message)
      }
    }
  }, [identity, onError])

  const reconnect = useCallback(async () => {
    try {
      config.logWebRTC &&
        console.log('Reconnecting... ', { localActiveTeacher })
      await reconnectSocket({
        socket,
        data: {
          ...identity,
          classroom: { teacher: localActiveTeacher }
        }
      })
      config.logWebRTC && console.log('Reconnected: ', socket.id)
      setState(state => ({ ...state, isReconnecting: false }))
    } catch (error) {
      console.error('Error reconnecting: ', error)
      onError('Ha ocurrido un error reconectando')
    }
  }, [identity, onError])

  const onUpdateTeacher = teacher => {
    setState(state => ({
      ...state,
      teachers: [
        ...state.teachers.filter(
          currentTeacher => currentTeacher._id !== teacher._id
        ),
        teacher
      ]
    }))
  }
  const onRemoveTeacher = teacher => {
    setState(state => {
      if (state.activeTeacher === teacher._id) {
        peerConnection && peerConnection.destroy()
        return {
          ...state,
          teachers: [
            ...state.teachers.filter(
              currentTeacher => currentTeacher._id !== teacher._id
            )
          ],
          activeTeacher: null
        }
      }
      return {
        ...state,
        teachers: [
          ...state.teachers.filter(
            currentTeacher => currentTeacher._id !== teacher._id
          )
        ]
      }
    })
  }
  const peerSignal = useCallback(({ teacherId, signal }) => {
    sendLogMessage({
      socket,
      message: `Successfully received signal data of type (${
        signal.type || 'candidate'
      }) from teacher=${teacherId}`
    })
    if (localActiveTeacher !== teacherId) {
      sendLogMessage({
        socket,
        message: `Got signal from teacher with id="${teacherId}" but it's not my teacher`
      })
      return
    }
    peerConnection.signal(signal)
  }, [])

  const handleHangUpCall = useCallback(() => {
    setState(state => ({ ...state, blockNavigation: false }))
    setTimeout(() => {
      // if (!!attendance.id)
      window.open(
        window.location.protocol +
          '//' +
          config.host +
          '/rating/' +
          (attendance.id || 'trial'),
        '_self'
      )
      onError('El profesor terminó la llamada')
    }, 500)
  }, [attendance.id, onError])

  const disconnect = useCallback(
    reason => {
      console.warn('Disconnected: ', reason)
      if (
        reason.includes('ping') ||
        reason.includes('transport close') ||
        reason.includes('transport error')
      ) {
        setState(state => ({ ...state, isReconnecting: true }))
        return
      }
      Sentry.captureMessage(
        'Received disconnection event from the API:' + reason
      )
      onError('Se ha perdido la conexión con Classfy')
    },
    [onError]
  )

  const initSocketEvents = useCallback(() => {
    if (!socket) socket = io(config.apiURL)

    config.logWebRTC && console.log('init socket events', !!socket)

    socket.on('connect', connect)
    socket.io.on('reconnect', reconnect)
    socket.on('academy:teachers:connected', onUpdateTeacher)
    socket.on('academy:teachers:disconnected', onRemoveTeacher)
    socket.on('classroom:students:peer-signal', peerSignal)
    socket.on('classroom:teachers:hang-up-call', handleHangUpCall)
    socket.on('disconnect', disconnect)
    socket.on('connect_error', e => console.error('connect_error: ', e))
    socket.io.on('close', () => console.warn(`CLOSED SOCKET!`))
  }, [connect, disconnect, handleHangUpCall, peerSignal, reconnect])

  const endSocketEvents = useCallback(() => {
    config.logWebRTC && console.log('endSocketEvents:', socket)
    if (!socket) return
    socket.off('classroom:students:peer-signal')
    socket.off('academy:teachers:connected')
    socket.off('academy:teachers:disconnected')
    socket.disconnect()
    socket = null
  }, [])

  const endPeerConnection = useCallback(() => {
    config.logWebRTC && console.log('endPeerConnection:', peerConnection)
    try {
      if (peerConnection) peerConnection.destroy()
    } catch (error) {
      config.logWebRTC && console.error('endPeerConnection:', error)
    } finally {
      config.logWebRTC && console.log('endPeerConnection: cleaned')
      peerConnection = null
    }
  }, [])

  const toogleIsWaiting = () =>
    setState(state => ({ ...state, isWaiting: !state.isWaiting }))

  const handleActiveTeacher = useCallback(
    async teacher => {
      try {
        await joinTeacherClass({ socket, teacher })
        const stream = await initUserMedia()
        localStreamRef.current = stream
        peerConnection = new Peer({
          config: { iceServers },
          stream: stream,
          initiator: true
        })
        peerConnection.on('signal', signal => {
          console.warn('Signal is of type:', signal.type || 'candidate')
          // peerConnection._debug = console.log
          sendPeerSignal({
            socket,
            signal,
            teacher
          })
        })
        peerConnection.on('connect', () =>
          sendLogMessage({
            socket,
            message: `Successfully established WebRTC connection with teacher id="${teacher}"`
          })
        )
        peerConnection.on('stream', remoteStream => {
          console.warn('Remote stream!')
          setState(state => ({ ...state, remoteStream }))
        })

        const onWebRTCError = err => {
          console.error('Error with WebRTC:', err || 'see back logs')
          Sentry.captureException(err)
          setState(state => ({ ...state, blockNavigation: false }))
          setTimeout(() => {
            onError('Error con la videollamada')
          }, 500)
        }
        const onWebRTCClose = () => {
          console.warn('Peer connection closed')
          setState(state => ({ ...state, blockNavigation: false }))
          setTimeout(() => {
            endPeerConnection()
            onError('Videollamada terminada')
          }, 500)
        }

        peerConnection.on('error', onWebRTCError)
        peerConnection.on('close', onWebRTCClose)

        setState(state => ({
          ...state,
          activeTeacher: teacher,
          localStream: stream
        }))
        localActiveTeacher = teacher

        isTest ? toogleIsWaiting() : navigate('classroom')
      } catch (err) {
        console.error('Error connecting with teacher: ', err)
        Sentry.captureException(err)
        onError('No se ha podido conectar con el profesor')
      }
    },
    [endPeerConnection, iceServers, isTest, initUserMedia, navigate, onError]
  )

  useEffect(() => {
    if (!socket) return
    socket.on('classroom:teachers:select-student', activeStudentId => {
      // @ts-ignore
      if (identity.id === activeStudentId && isWaiting) navigate('classroom')
    })
    return () => {
      if (!socket) return
      socket.off('classroom:teachers:select-student')
    }
  }, [identity, isWaiting, navigate])

  useEffect(() => {
    if (!canConnectSocketEvents) return
    initSocketEvents()
  }, [initSocketEvents, canConnectSocketEvents])

  useEffect(() => {
    if (
      // getVideoTourViewedValue() &&
      getHasMediaPermissionsValue() &&
      location.pathname === '/academy'
    )
      prepareConnection()
  }, [
    getHasMediaPermissionsValue,
    // getVideoTourViewedValue,
    location.pathname,
    prepareConnection
  ])

  useEffect(() => {
    return () => {
      config.logWebRTC && console.log('Unmount')
      localAuthenticated = false
      localActiveTeacher = ''
      localStreamRef.current
      if (localStreamRef.current) {
        localStreamRef.current.getTracks().forEach(track => {
          track.stop()
        })
      }
      endSocketEvents()
      endPeerConnection()
    }
  }, [endPeerConnection, endSocketEvents])

  useEffect(() => {
    const previous = previousLocation?.pathname
    if (!previous || previous == '/academy/media-permissions') return
    const samePath = location.pathname === previous
    console.log('path:', !samePath && location.pathname === '/academy')
    if (!samePath && location.pathname === '/academy') {
      // End connection
      endSocketEvents()
      endPeerConnection()
      onError('Se ha perdido la conexión con Classfy')
    }
  }, [
    endPeerConnection,
    endSocketEvents,
    location.pathname,
    onError,
    previousLocation
  ])
  config.logWebRTC && console.log(location.pathname, previousLocation?.pathname)
  const teacherData = teachers.find(({ _id }) => _id === activeTeacher)
  return {
    iceServers,
    teachers,
    teacherData,
    activeTeacher,
    localStream,
    remoteStream,
    selectedMedia: getSelectedMediaValue(),
    authenticated,
    isReconnecting,
    canConnectSocketEvents,
    socket,
    identity,
    location,
    isTest,
    isWaiting,
    blockNavigation,
    toogleIsWaiting,
    requestIceServers,
    saveSelectedMedia,
    initUserMedia,
    changeStream,
    initSocketEvents,
    prepareConnection,
    endSocketEvents,
    endPeerConnection,
    handleActiveTeacher
  }
}

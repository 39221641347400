import * as React from 'react'

const Download = props => (
  <svg
    width='100%'
    height='100%'
    viewBox='0 0 16 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M10.038 18H5.209A5.215 5.215 0 0 1 0 12.79a1.059 1.059 0 1 1 2.118 0 3.095 3.095 0 0 0 3.091 3.092h4.829a3.095 3.095 0 0 0 3.091-3.092 1.059 1.059 0 1 1 2.118 0 5.215 5.215 0 0 1-5.21 5.21Z'
      fill={props.color || '#fff'}
    />
    <path
      d='M7.623 12.918a1.059 1.059 0 0 1-1.059-1.059v-10.8a1.059 1.059 0 1 1 2.118 0v10.8c0 .585-.474 1.059-1.059 1.059Z'
      fill={props.color || '#fff'}
    />
    <path
      d='M7.624 13.469h-.007a1.059 1.059 0 0 1-.78-.35L1.83 7.571a1.059 1.059 0 0 1 1.573-1.419l4.232 4.69 4.222-4.552a1.059 1.059 0 1 1 1.553 1.44L8.4 13.13a1.06 1.06 0 0 1-.776.338Z'
      fill={props.color || '#fff'}
    />
  </svg>
)

export default Download

import React from 'react'
import QRCode from 'qrcode.react'
import { useAuth } from 'context/AuthProvider'
import config from 'config'
import { H3, Paragraph } from 'components/typography'
import Button from 'components/buttons/Button'
import Modal from './Modal'
import styles from './UploadPhotoModal.module.css'

function UploadPhotoModal({ onClose }) {
  const { student } = useAuth()
  return (
    <Modal
      cancelText='Cerrar'
      showActions={false}
      onCancel={onClose}
      showCloseIcon
    >
      <div className={styles.uploadPhotoSection}>
        <H3>Subir fotografía a la clase 📸</H3>
        <Paragraph className={styles.uploadDescriptionContainer}>
          Escanea el código QR para subir tus fotos desde el móvil. Si lo
          prefieres, también puedes cargar imágenes desde tu ordenador con un
          simple click en el botón.
        </Paragraph>
        <div className={styles.uploadButtonContainer}>
          <QRCode
            value={`${config.host}/upload-photo/${student.id}`}
            size={180}
            className={styles.qr}
          />
          <a href={`/upload-photo/${student.id}`} target='_blank'>
            <Button size='tiny'>Subir foto desde el ordenador</Button>
          </a>
        </div>
      </div>
    </Modal>
  )
}

export default UploadPhotoModal

//@ts-check
import React, { useCallback, useEffect, useState } from 'react'
import { isEmpty } from 'ramda'
import Markdown from 'react-markdown'
import rehypeKatex from 'rehype-katex'
import remarkMath from 'remark-math'
import { requestImageList } from 'api/sockets'
import useBarbeibot from 'hooks/useBarbeibot'
import Send from 'assets/icons/Send'
import Cross from 'assets/icons/Cross'
import Upload from 'assets/icons/Upload'
import CoolAndSmart from 'assets/svg/CoolAndSmart'
import { H2, H5, Paragraph } from 'components/typography'
import Button from 'components/buttons/Button'
import TextArea from 'components/inputs/TextArea'
import DotsSpinner from 'components/spinners/DotsSpinner'
import GalleryModal from 'components/modals/GalleryModal'
import UploadPhotoModal from 'components/modals/UploadPhotoModal'
import Stepper from 'components/stepper'
import { formatDay } from 'utils/date'
import { getMimeTypeFromUrl } from 'utils/files'
import styles from './ClassroomBot.module.css'
import 'katex/dist/katex.min.css'

const ClassroomBot = ({ socket, student, teacher, isVisible }) => {
  const [step, setStep] = useState(0)
  const [question, setQuestion] = useState('')
  const [showZoom, setShowZoom] = useState(false)
  const [showUploadModal, setShowUploadModal] = useState(false)
  const { gpt, handleDrop, handleSubmit } = useBarbeibot({ socket, teacher })

  const handleSelectImage = (image, mediaType) => {
    handleDrop(image, mediaType)
    setStep(2)
  }

  const formattedContent = (gpt.message || '')
    .replaceAll('\\[', '$$')
    .replaceAll('\\]', '$$')
    .replaceAll('\\(', '$')
    .replaceAll('\\)', '$')
  const photo = gpt.image
  const isLoading = gpt.isLoading
  const disabledSendButton = isLoading || !photo
  return (
    <div className={styles.container} hidden={!isVisible}>
      <div className={styles.innerContainer}>
        <Stepper
          activeStep={step}
          classNames={{
            container: styles.bot,
            stepper: styles.stepToolbar,
            content: styles.stepContent
          }}
          steps={[
            {
              title: 'Instrucciones',
              component: (
                <div className={styles.instructions}>
                  <CoolAndSmart className={styles.svg} />
                  <H2>Resuelve tus dudas con Barbei-bot</H2>
                  <Paragraph type='body1Bold'>
                    Selecciona la imagen sobre la que tienes dudas y haz tu
                    pregunta
                  </Paragraph>

                  <Button
                    label='Seleccionar'
                    size='small'
                    onClick={() => setStep(1)}
                  />
                </div>
              )
            },
            {
              title: 'Selecciona una imagen',
              component: (
                <ImageSync
                  socket={socket}
                  student={student.id}
                  onImageSelect={handleSelectImage}
                  onBack={() => setStep(0)}
                  onShowUploadModal={() => setShowUploadModal(true)}
                />
              )
            },
            {
              title: 'Pregunta',
              component: (
                <div className={[].join(' ')}>
                  <div className={styles.questionArea}>
                    {photo ? (
                      <div className={styles.imgContainer}>
                        <img
                          className={styles.img}
                          src={photo}
                          onClick={() => setShowZoom(true)}
                        />
                        {photo && (
                          <Cross
                            className={styles.crossIcon}
                            color='var(--bright)'
                            onClick={() => handleDrop()}
                          />
                        )}
                      </div>
                    ) : (
                      <div
                        className={styles.emptyImg}
                        onClick={() => setStep(1)}
                      >
                        <Paragraph type='body2'>
                          Selecciona una imagen
                        </Paragraph>
                      </div>
                    )}
                    <div className={styles.interaction}>
                      <TextArea
                        placeholder='(Opcional) Dale a Barbei-bot algo más de información'
                        className={styles.textArea}
                        value={question}
                        onChange={e => setQuestion(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className={styles.sendButton}>
                    <Button
                      size='tiny'
                      disabled={disabledSendButton}
                      onClick={() => handleSubmit(question)}
                    >
                      {isLoading ? (
                        <div className={styles.spinner}>
                          <DotsSpinner />
                        </div>
                      ) : (
                        <div className={styles.sendButtonText}>
                          Preguntar
                          <Send
                            className={[
                              styles.sendIcon,
                              disabledSendButton ? styles.disabled : ''
                            ].join(' ')}
                            color={
                              !isLoading && photo
                                ? 'var(--seadapted)'
                                : 'var(--disabled-color)'
                            }
                            onClick={() =>
                              !disabledSendButton && handleSubmit(question)
                            }
                          />
                        </div>
                      )}
                    </Button>
                  </div>
                  <Markdown
                    className={styles.markdown}
                    remarkPlugins={[remarkMath]}
                    rehypePlugins={[rehypeKatex]}
                  >
                    {formattedContent}
                  </Markdown>
                </div>
              )
            }
          ]}
          onChange={setStep}
        />
      </div>

      <div className={styles.tools}>
        <div />
        <div className={styles.right}>
          <div
            className={styles.innerRight}
            onClick={() => setShowUploadModal(true)}
          >
            <div className={styles.uploadButton} title='Subir imagen'>
              <Upload />
            </div>
          </div>
        </div>
      </div>
      {showZoom && (
        <GalleryModal
          initialIndex={0}
          photos={[{ location: photo }]}
          onCancel={() => setShowZoom(false)}
        />
      )}
      {showUploadModal && (
        <UploadPhotoModal onClose={() => setShowUploadModal(false)} />
      )}
    </div>
  )
}

export default ClassroomBot

function ImageSync({
  socket,
  student,
  onImageSelect,
  onBack,
  onShowUploadModal
}) {
  const [isFetching, setIsFetching] = useState(true)
  const [selectedImage, setSelectedImage] = useState(null)
  const [studentImages, setStudentImages] = useState([])

  const fecthData = useCallback(() => {
    setIsFetching(true)
    requestImageList({ socket, student })
      .then(images => {
        const sortedImages = images
          .map(image => ({
            ...image,
            timestamp: new Date(image.date).getTime(),
            day: formatDay(new Date(image.date))
          }))
          .sort((img1, img2) => img2.timestamp - img1.timestamp)
          .reduce((acc, curr) => {
            if (acc.length === 0) return [{ day: curr.day, elements: [curr] }]
            else if (acc[acc.length - 1].day === curr.day)
              return [
                ...acc.slice(0, -1),
                {
                  ...acc[acc.length - 1],
                  elements: [...acc[acc.length - 1].elements, curr]
                }
              ]
            else return [...acc, { day: curr.day, elements: [curr] }]
          }, [])
        setStudentImages(sortedImages)
      })
      .catch(console.error)
      .finally(() => setIsFetching(false))
  }, [socket, student])

  useEffect(() => {
    setSelectedImage(null)
  }, [student, socket])

  useEffect(() => {
    if (!socket) return
    fecthData()
  }, [fecthData, socket])

  const hasImages = studentImages && !isEmpty(studentImages)

  const commonActions = (
    <div className={styles.imageSyncActions}>
      <Button type='secondary' size='small' label='Volver' onClick={onBack} />
      <Button
        type='warning'
        size='small'
        label='Actualizar lista'
        onClick={fecthData}
      />

      {hasImages && (
        <Button
          label='Cargar'
          size='small'
          onClick={() =>
            onImageSelect(
              selectedImage.location,
              getMimeTypeFromUrl(selectedImage.location)
            )
          }
          disabled={!selectedImage}
        />
      )}
      {!hasImages && (
        <Button size='small' label='Subir' onClick={onShowUploadModal} />
      )}
    </div>
  )
  if (isFetching)
    return (
      <div className={styles.step1Empty}>
        <H2>
          Buscando imágenes <br />
          <div className={styles.spinner}>
            <DotsSpinner />
          </div>
        </H2>
      </div>
    )
  if (!studentImages || isEmpty(studentImages))
    return (
      <div className={styles.step1Empty}>
        <H2>
          Todavía no se han compartido <br /> imágenes
        </H2>
        {commonActions}
      </div>
    )
  return (
    <div className={styles.step1}>
      <div className={styles.imageSync}>
        {studentImages.map(day => (
          <div className={styles.day} key={day.day}>
            <H5 className={styles.title}>{day.day}</H5>
            <div className={styles.content}>
              {day.elements.map((image, index) => (
                <img
                  className={[
                    styles.image,
                    selectedImage && selectedImage.id === image.id
                      ? styles.selected
                      : ''
                  ].join(' ')}
                  onClick={() => setSelectedImage(image)}
                  onDoubleClick={() => {
                    setSelectedImage(image)
                    onImageSelect(
                      image.location,
                      getMimeTypeFromUrl(image.location)
                    )
                  }}
                  key={index}
                  src={image.thumbnail}
                  title='Puedes dar doble clic'
                  alt='Foto de mundoestudiante'
                />
              ))}
            </div>
          </div>
        ))}
      </div>
      {commonActions}
    </div>
  )
}

import * as React from 'react'

const Upload = props => (
  <svg
    width='100%'
    height='100%'
    viewBox='0 0 16 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M10.038 18H5.209A5.215 5.215 0 0 1 0 12.79a1.059 1.059 0 1 1 2.118 0 3.095 3.095 0 0 0 3.091 3.092h4.829a3.095 3.095 0 0 0 3.091-3.092 1.059 1.059 0 1 1 2.118 0A5.215 5.215 0 0 1 10.038 18Z'
      fill={props.color || '#fff'}
    />
    <path
      d='M7.623 1.059a1.059 1.059 0 0 1 1.059 1.059v10.8a1.059 1.059 0 1 1-2.118 0V2.118c0-.585.474-1.059 1.059-1.059Z'
      fill={props.color || '#fff'}
    />
    <path
      d='M7.624 0h.007a1.059 1.059 0 0 1 .78.35l4.989 4.548a1.059 1.059 0 1 1-1.553 1.44L7.624 1.786 3.404 5.339a1.059 1.059 0 1 1-1.573-1.419L6.823.37c.206-.188.482-.292.776-.338Z'
      fill={props.color || '#fff'}
    />
  </svg>
)

export default Upload

import React, { useEffect, useState } from 'react'
import { findAttendances } from 'api/attendances'
import { useNotificationActions } from 'context/NotificationProvider'
import { H2 } from 'components/typography'
import AttendanceCalendar from 'components/calendars/AttendanceCalendar'
import styles from './Calendar.module.css'

function Calendar() {
  const [attendances, setAttendances] = useState([])
  const [isFetching, setIsFetching] = useState(true)
  const [{ month, year }, setMonthYear] = useState({
    month: new Date().getMonth(),
    year: new Date().getFullYear()
  })
  const { setErrorMessage } = useNotificationActions()

  useEffect(() => {
    setIsFetching(true)
    findAttendances(month, year)
      .then(setAttendances)
      .catch(() => {
        setErrorMessage({
          message: 'No hemos podido encontrar tus asistencias del mes 😔'
        })
      })
      .finally(() => setIsFetching(false))
  }, [month, year, setErrorMessage])
  return (
    <section className={styles.section}>
      <H2>Calendario 🗓</H2>
      <div className={styles.calendar}>
        <AttendanceCalendar
          attendances={attendances}
          month={month}
          year={year}
          disabled={isFetching}
          isLoading={isFetching}
          onChangeMonth={setMonthYear}
        />
      </div>
    </section>
  )
}

export default Calendar

import React, { useState } from 'react'
import styles from './Tooltip.module.css'

function Tooltip({
  title,
  classNames = { title: '' },
  placement = 'top',
  children = null
}) {
  const [showTooltip, setShowTooltip] = useState(false)

  const handleMouseEnter = () => {
    setShowTooltip(true)
  }

  const handleMouseLeave = () => {
    setShowTooltip(false)
  }
  return (
    <div className={styles.tooltipContainer}>
      <div
        className={styles.tooltipTrigger}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {children}
      </div>
      {showTooltip && (
        <div
          className={[
            styles.tooltipTitle,
            styles[`--${placement}`],
            classNames?.title
          ].join(' ')}
        >
          {title}
        </div>
      )}
    </div>
  )
}

export default Tooltip
